import React, { useEffect } from "react";
import SEO from "components/seo";
import Intro from "components/sections/Intro";
import About from "components/sections/About";
import Bio from "components/sections/Bio";
import Gallery from "components/sections/Gallery";
import Paper from "components/sections/Paper";
import Contact from "components/sections/Contact";
import Footer from "components/sections/Footer";
import Video from "components/video";
import scrollTo from "gatsby-plugin-smoothscroll";
import { useTranslateContext } from "gatsby-plugin-translate";

// Assets
import logo from "images/logo-white.png";
import shop from "images/shop.svg";

// Styles
import "styles/main.css";

export default () => {
  const { language } = useTranslateContext();

  useEffect(() => {
    var prevScrollpos = window.pageYOffset || 100;

    window.onscroll = function () {
      var currentScrollPos = window.pageYOffset;
      if (prevScrollpos > currentScrollPos) {
        document.getElementById("navbar").style.top = "0";
      } else {
        document.getElementById("navbar").style.top = "-100px";
      }
      prevScrollpos = currentScrollPos;
    }

  }, [])

  return (
    <div className="home" id="home">
      <div id="navbar" style={{position: 'relative',
      transition: 'top 0.3s ease-in'    }}>
      <div
        role="button"
        tabIndex={0}
        onKeyDown={() => scrollTo("#home")}
        onClick={() => scrollTo("#home")}
      >
        {/* <img className="fixed-logo" src={logo} alt="Isabel de Andrade" /> */}
      </div>
      {/* <a
        href={language !== "pt" ? `/${language}/shop` : "/shop"}
        className="fixed-shop-icon"
      >
        <img src={shop} alt="Go to shop" />
      </a> */}
      </div>
      <SEO title="Isabel de Andrade | Esculturas" />
      <Video />
      <Intro />
      <About />
      <Bio />
      <Gallery />
      <Paper />
      <Contact />
      <Footer />
    </div>
  );
};
